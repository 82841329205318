export const API_ENVIRONMENTS = {
  DEV:'http://localhost:8000',
  STAGE:'http://localhost:8000',
  PROD:'https://beta.appeals.sba.gov/api'  
}

export const BASE_URL = (()=>{
  if(process.env.BASE_URL) {
    return process.env.BASE_URL;
  }
  else {
    if(process.env.API_ENV){
      let _preset = Object.keys(API_ENVIRONMENTS).filter((key)=>{
        return API_ENVIRONMENTS[process.env.API_ENV];
      });
      if(_preset[0]){
        return API_ENVIRONMENTS[process.env.API_ENV];
      }else{
        return process.env.API_ENV;
      }
    }else{
      return API_ENVIRONMENTS['DEV'];
    }
  }
})();

export const AUTH_API = {
  LOGIN:BASE_URL+"/users/auth/login",
  LOGOUT:BASE_URL+'/users/auth/logout',
  REGISTRATION:BASE_URL+"/users/auth/registration",
  RESET_PASSWORD:BASE_URL+"/users/auth/password/reset",
  RESET_PASSWORD_CONFIRM:BASE_URL+"/users/auth/password/reset/confirm",
  CURRENT_USER:BASE_URL+"/users/auth/user",
  REFRESH_TOKEN:BASE_URL+"/users/auth/refresh_token",
  TWILIO_SEND: BASE_URL+"/2fa-api/send",
  TWILIO_VERIFY: BASE_URL+"/2fa-api/verify",
  TWILIO_SET_PHONE: BASE_URL+"/2fa-api/set-phone-number",
}

export const USER_API = {
  USER_AUTH:BASE_URL+'/users/auth/user',
  USER:BASE_URL+'/api/user',
  USERS:BASE_URL+'/api/users',
  GROUPS:BASE_URL+'/api/group',
  UPDATE_USER: BASE_URL + '/users/auth/user',

  //partials
  PROFILE :  '/profile',
  ASSIGNEE_USER: '/user',
  ASSIGNEE_REASSIGN:'/reassign',
  MFA_RESET: '/mfa-reset'

}

export const RESOURCES_API = {
  RESOURCES:BASE_URL+'/api/resources',
  RESOURCES_FAQS:BASE_URL+'/api/faqs',
  RESOURCES_ASSET:BASE_URL+'/api/resource-asset'
}

export const APPEAL_ROOTS = {
  // use in conjunction with a PARTIAL or alone
  APPEALS_ROOT:BASE_URL+'/api/appeals',
  APPEAL_ROOT:BASE_URL+'/api/appeal',
  GROUP_ROOT:BASE_URL+'/api/group',
  APPEAL_NOTES:BASE_URL+'/api/notes',
  APPEAL_NOTE:BASE_URL+'/api/note',
  APPEAL_LENDERS:BASE_URL+'/api/lender',
  APPEAL_TYPE:BASE_URL+'/api/appeal-type',
  APPEAL_VETERAN: BASE_URL + '/api/appeal-principal-veteran-status',
  APPEAL_GENDER:BASE_URL + '/api/appeal-principal-gender',
  APPEAL_RACE:BASE_URL + '/api/appeal-principal-race',
  APPEAL_ETHNICITY:BASE_URL+ '/api/appeal-principal-ethnicity',
  APPEAL_INDUSTRY:BASE_URL+'/api/appeal-principal-industry',
  APPEAL_ACTION:BASE_URL + '/api/action',
  APPEAL_DISPOSITION:BASE_URL + '/api/appeal-disposition',
  APPEAL_DISPOSITION_DISMISSED:BASE_URL + '/api/appeal-disposition-dismissed',
  APPEAL_INVITATION:BASE_URL + '/api/invitation',
  SETTINGS: BASE_URL + '/api/settings',
  ADMINISTRATIVE_RECORD : BASE_URL + '/api/administrative-record',
  ADMINISTRATIVE_RECORD_ASSET : BASE_URL + '/api/administrative-record-asset',
  APPEAL_PUBLIC_NOTE:BASE_URL+'/note-public',
  BASE_API: BASE_URL
}

export const DOCUMENT_ENDPOINTS = {
  GENERATE_DOCUMENT:'/generated-document'
}

export const OPTION_LIST_ENDPOINTS = {
  DOCUMENT_CATEGORIES:BASE_URL+'/api/document-category',
  DOCUMENT_CATEGORIES_APPELLANT:BASE_URL+'/api/document-category',
  GENERATABLE_DOCUMENTS:BASE_URL+'/api/appeal-generated-document',
}

export const APPEAL_PARTIALS = {
  // Use in conjunction with a ROOT
  APPEAL_DETAIL:'/detail',
  APPEAL_DOCKETS:'/docket-filing',
  APPEAL_PRIMARY_CONTACT_OGC:'/primary-contact/?group__name=OGC',
  APPEAL_NOTE: '/note',
  APPEAL_PUBLIC_NOTE:'/note-public',
  APPEAL_DOCUMENTS:'/document',
  APPEAL_DOCUMENT:'/document',
  APPEAL_ACTIONS:'/action',
  APPEAL_CERTIFICATE_OF_SERVICE:'/certificate-of-service',
  APPEAL_AUDIT: '/audit-log',
  PRINCIPAL:'/principal',
  GROUP:'/group',
  PRIMARY_CONTACT:'/primary-contact',
  DOCKET_FILING_DOCUMENT : '/docket-filing-document'
}

export const EXTERNAL_ENDPOINTS = {
  SMARTY_STREETS_VALIDATE: 'https://us-street.api.smartystreets.com/street-address?',
  SMARTY_STREETS_LOOKUP: 'https://us-autocomplete-pro.api.smartystreets.com/lookup?'
}

export const API_ENDPOINTS = Object.assign({},
  AUTH_API,
  USER_API,
  APPEAL_ROOTS,
  RESOURCES_API,
  APPEAL_PARTIALS,
  OPTION_LIST_ENDPOINTS,
  EXTERNAL_ENDPOINTS,
  DOCUMENT_ENDPOINTS,
  {
    DJANGO_ADMIN:BASE_URL.replace(/(\/v1)+/g,'')+'/admin/'
  }
);
